<template>
  <v-dialog v-model="localDialog" max-width="600px">
    <v-tabs
      v-model="tab"
      centered
      icons-and-text
      dark
      fixed-tabs
      background-color="primary"
    >
      <v-tab href="#tab-1">
        Terminate contract
        <v-icon>mdi-clipboard-text-off</v-icon>
      </v-tab>
      <v-tab href="#tab-2">
        Remove record
        <v-icon>mdi-clipboard-remove</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <div class="body-2 pa-2">
          This action will terminate contract for selected employee and will be
          shown in statistics
        </div>
        <form-wizard
          :form-data="formDataTermination"
          :item="item"
          :namespace="namespace"
          :title="title"
          :go-to="goTo"
          @dialog-cancel="updateDialog(false)"
          :icon="icon"
          :store="store"
          :store-action="storeActionTermination"
        ></form-wizard>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <div class="body-2 pa-2">
          This action will just remove employee from this workforce request and
          will not effect statistics (f.e. human error)
        </div>
        <form-wizard
          :form-data="formDataRemove"
          :item="item"
          :namespace="namespace"
          :title="title"
          :go-to="goTo"
          @dialog-cancel="updateDialog(false)"
          :icon="icon"
          :store="store"
          :store-action="storeActionRemove"
        ></form-wizard>
      </v-tab-item>
    </v-tabs-items>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    jobPosition: {
      required: true
    },
    employeeId: {
      required: false
    },
    workforceId: {
      required: true
    },
    goToUrl: {
      required: false
    }
  },
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  computed: {
    ...mapGetters({
      terminationReasons: "employees/terminationReasons"
    }),

    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog-update", value);
      }
    },
    storeActionTermination() {
      return "employees/terminateEmployee";
    },
    storeActionRemove() {
      return "employees/unassignEmployee";
    },
    store() {
      return "employee";
    },

    icon() {
      return "";
    },
    title() {
      return null;
      //  return "Assign employee to job position";
    },
    namespace() {
      return "employee";
    },
    goTo() {
      if (this.goToUrl) {
        return this.goToUrl;
      }
      return "/workforce/show/" + this.workforceId;
    },
    formDataTermination() {
      let form = [
        {
          text: "Contract termination date date*",
          value: "terminated_at",
          flex: "col-sm-12 col-md-12",
          required: "required",
          fullDate: true,
          class: "",
          validate: "required"
        },
        {
          text: "Termination Reason*",
          value: "termination_type_id",
          flex: "col-sm-12 col-md-12",
          displayName: true,
          required: "required",
          treeview: true,
          selectionType: "independent",
          items: this.terminationReasons,
          class: "",
          validate: "required"
        },
        {
          text: "Termination note*",
          value: "termination_note",
          flex: "col-sm-12 col-md-12",
          required: "",
          textarea: true,
          class: "",
          validate: ""
        }
      ];
      return form;
    },
    formDataRemove() {
      let form = [];
      return form;
    }
  },
  data() {
    return {
      tab: null,
      item: {
        job_position_id: this.jobPosition,
        id: this.employeeId,
        workforce_request_id: this.workforceId
      }
    };
  },

  async mounted() {
    await this.$store.dispatch("employees/terminationReasons", { all: true });
  },
  created() {},
  methods: {
    updateDialog(dialog) {
      this.localDialog = dialog;
      //  location.reload();
    },
    cancelDialog() {
      this.$emit("dialog-cancel", true);
    }
  }
};
</script>
